import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { Icon } from '@momentum-ui/react'
import {
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel'
import Link from '../Link'
import { linkRenderer } from '../../utils/markdown.utils'
import { makeKebabCase } from '../../utils/general.utils'
import { graphql, useStaticQuery } from 'gatsby'

interface Media {
  url: string
  alternativeText: string
}

export interface ICarouselSlide {
  name: string
  carouselContent: string
  carouselLogo: Media
  carouselVideo: Media
  carouselVideoPreviewImage: Media
}

const PartnerCarousel: React.FC = (): JSX.Element => {
  const { allStrapiPartnerShowCases } = useStaticQuery(
    graphql`
      query {
        allStrapiPartnerShowCases {
          nodes {
            name
            carouselContent
            carouselLogo {
              url
              alternativeText
            }
            carouselVideo {
              url
              alternativeText
            }
            carouselVideoPreviewImage {
              url
              alternativeText
            }
          }
        }
      }
    `
  )
  const slides = allStrapiPartnerShowCases.nodes
  const { t } = useTranslation()
  return (
    <section className="partner-carousel">
      <h1 className="partner-carousel-section-header">
        <span>
          {`{ `}
          <span className="header-text">{t('home.partnerSuccessStories')}</span>
          {` }`}
        </span>
      </h1>
      <CarouselProvider
        naturalSlideWidth={2}
        naturalSlideHeight={1}
        totalSlides={6}
        infinite={true}
        isIntrinsicHeight={true}
        isPlaying={true}
      >
        <div className="slide-wrap">
          <DotGroup
            showAsSelectedForCurrentSlideOnly={true}
            className="slide-dot-group"
          />
          <Slider className="slider">
            {slides.map((slide: ICarouselSlide, index: number) => {
              return (
                <Slide key={index} index={index}>
                  <div className="slide-container">
                    <div className="slide-content-left">
                      <div className="logo-container">
                        <img
                          src={slide.carouselLogo.url}
                          className="partner-logo"
                          alt={slide.carouselLogo.alternativeText}
                        />
                      </div>
                      <div className="video-container">
                        <img
                          src={slide.carouselVideoPreviewImage.url}
                          alt={slide.carouselVideoPreviewImage.alternativeText}
                        />
                      </div>
                    </div>
                    <div className="slide-content-right">
                      <div className="story-container">
                        <div className="top-section">
                          <ReactMarkdown
                            source={slide.carouselContent}
                            renderers={linkRenderer}
                            escapeHtml={false}
                          />
                        </div>
                        <div className="bottom-section">
                          <Link
                            to={`/partner-showcase/${makeKebabCase(
                              slide.name
                            )}`}
                            className="default-link"
                            target={'_blank'}
                          >
                            {`${t('home.partnerStoryLink')}  `}
                            <Icon name="next_14" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
              )
            })}
          </Slider>
          <div className="slide-btn-wrapper">
            <ButtonNext className="slide-next-btn">
              <Icon name="arrow-right-optical_16" className="slide-btn-icon" />
            </ButtonNext>
          </div>
        </div>
      </CarouselProvider>
    </section>
  )
}

export default PartnerCarousel
