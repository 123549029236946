import React from 'react'
import { useTranslation } from 'react-i18next'
import ResourceCard from './ResourceCard'
import { StaticImage } from 'gatsby-plugin-image'

export interface IResourceCard {
  href: string
  hrefText: string
  label: string
  body: string
  img: JSX.Element
  partner?: string
}

export const ResourceCardSection: React.FC = () => {
  const { t } = useTranslation()

  const resources = [
    {
      href: 'https://blog.webex.com/category/customer-experience/',
      hrefText: t('resources.webexResourceGoToBlog'),
      label: t('resources.webexCustomerExperienceBlogResourceLabel'),
      body: t('resources.webexCustomerExperienceBlogResourceBody'),
      img: (
        <StaticImage
          src="../../assets/images/blog-image.png"
          alt={t(`home.blogImageAltText`)}
          className=""
          loading="eager"
          objectFit="contain"
        />
      ),
    },
    {
      href: 'https://www.webex.com/products/customer-experience.html',
      hrefText: t('resources.webexResourceGoToSite'),
      label: t('resources.webexCustomerExperienceResourceLabel'),
      body: t('resources.webexCustomerExperienceResourceBody'),
      img: (
        <StaticImage
          src="../../assets/images/cx-product-image.png"
          alt={t(`home.customerExperienceAltText`)}
          className=""
          loading="eager"
          objectFit="contain"
        />
      ),
    },
    {
      href: process.env.WEBEX_DEVELOPER_URL,
      hrefText: t('resources.webexResourceGoToSite'),
      label: t('resources.webexDeveloperPortalResourceLabel'),
      body: t('resources.webexDeveloperPortalResourceBody'),
      img: (
        <StaticImage
          src="../../assets/images/dev-portal-code-image.png"
          alt={t(`home.developerPortalAltText`)}
          className=""
          loading="eager"
          objectFit="contain"
        />
      ),
    },
  ]

  return (
    <>
      {resources.map((resource, i) => {
        const { href, hrefText, label, body, img } = resource

        return (
          <ResourceCard
            href={href}
            hrefText={hrefText}
            label={label}
            body={body}
            img={img}
            key={i}
          />
        )
      })}
    </>
  )
}

export default ResourceCardSection
